@use '@scss/index.scss' as config;

.layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;

  &.sidebar {
    width: calc(100% - config.$sidebar-xs);
    // TODO: find the culprit making the page overflow.
    overflow-x: hidden;

    @include config.breakpoint('sm') {
      width: calc(100% - config.$sidebar-sm);
    }

    @include config.breakpoint('xl') {
      width: calc(100vw - config.$sidebar-lg);
    }
  }

  &.sidebar:not(.noPadd) {
    padding-top: 4px;

    @include config.breakpoint('sm') {
      padding-top: config.getSpacer('xs');
    }
  }
}

.main {
  flex: 1;
}
