@use '@scss/index.scss' as config;

.small {
  width: 100px;
}

.medium {
  width: 125px;
}

.large {
  width: 200px;
}

.small-logo {
  display: block;
  transform: scale(0.8);
  transform-origin: center center;
  margin: config.getSpacer('xxs') auto;

  @include config.breakpoint('xl') {
    display: none;
  }
}

.large-logo {
  display: none;

  @include config.breakpoint('xl') {
    display: block;
  }
}
