@use '@scss/index.scss' as config;

%link {
  display: inline-block;

  & > .underline {
    display: inline-block;
  }

  & > .underline::after {
    content: '';
    border-bottom: 1px config.getColour('primary50') solid;
    width: 100%;
    display: block;
  }

  &:hover {
    cursor: pointer;
  }
}

@mixin primaryLink {
  @extend %link;
  color: config.getColour('primary50');
  text-decoration: none;

  &:hover {
    color: config.getColour('white');
  }

  &:hover > .underline::after {
    border-bottom: 1px config.getColour('white') solid;
  }
}

@mixin secondaryLink {
  @extend %link;
  color: config.getColour('complementary2');
  text-decoration: none;

  &:hover {
    color: config.getColour('white');
  }

  &:hover > .underline::after {
    border-bottom: 1px config.getColour('white') solid;
  }
}

.primary {
  @include primaryLink();
}

.secondary {
  @include secondaryLink();
}

.nav {
  font-family: config.getTypo('h300', 'family');
  font-size: config.getTypo('h300', 'size');
  padding: config.getSpacer('xs');
  display: block;
}

.disabled {
  color: config.getColour('grey80');
  
  &:hover {
    cursor: not-allowed !important;
    color: config.getColour('grey80');
  }
}