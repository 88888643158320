@use '@scss/index.scss' as config;

.header {
    width: 100%;
    display: block;
    height: 82px;
    padding: config.getSpacer('xxs') config.getSpacer('sm');
    display: flex;
    align-items: center;
    border-bottom: 1px solid config.getColour('grey80');
    position: relative;
    z-index: 999;
}

.wrapper {
    display: block;
    width: 100%;
}