.small > svg {
    height: 12px;
    width: 12px;
    display: inline-block;
   
}

.medium > svg {
    height: 24px;
    width: 24px;
    display: inline-block;

}

.large > svg {
    height: 32px;
    width: 32px;
    display: inline-block;
}

.disabled {
    cursor: not-allowed !important;
}