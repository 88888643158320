@use '@scss/index.scss' as config;

.sidebar {
  width: config.$sidebar-xs;
  background-color: config.getColour('grey100');
  padding: 0;
  position: sticky;
  max-height: calc(var(--vh, 1vh) * 100);
  top: 0;
  border-right: config.getBorder('small');
  min-height: 690px;
  z-index: 10;
  height: calc(var(--vh, 1vh) * 100);

  @include config.breakpoint('sm') {
    width: config.$sidebar-sm;
    padding: config.getSpacer('xs');
  }

  @include config.breakpoint('xl') {
    width: 325px;
    min-width: 325px;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  padding: 5px 5px;
  width: 100%;
  min-width: 75px;

  @include config.breakpoint('sm') {
    padding: config.getSpacer('sm') 0;
  }

  @include config.breakpoint('xl') {
    padding: config.getSpacer('md');
    justify-content: flex-start;
  }
}

.underline {
  border-top: config.getBorder('small');
  border-bottom: config.getBorder('small');
}

.details-wrapper {
  padding-left: config.getSpacer('xs');
  display: flex;
  align-items: center;
  width: calc(100% - 75px);

  @include config.breakpoint('xl', 'down') {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  & > div > a {
    margin-top: config.getSpacer('xs');
  }
}

.details {
  display: block;
  font-weight: config.getTypo('h200', 'weight');
}

.subdetails {
  font-weight: config.getTypo('bodySmall', 'weight');
  color: config.getColour('grey60');
}

.footer {
  position: absolute;
  bottom: config.getSpacer('md');
  left: 50%;
  right: 0;
  transform: translateX(-50%);

  // Support icon
  & .nav-list-item > a {
    padding-left: 0;
    transform: scale(0.85);
    transform-origin: left;

    @include config.breakpoint('md', 'down') {
      display: flex;
      justify-content: center;
    }

    & svg {
      margin-bottom: -7px;
      transform: scale(0.85);

      @include config.breakpoint('lg', 'down') {
        left: 3px;
        position: relative;
      }
    }

    &:hover svg path, &:focus svg path {
      fill: config.getColour('primary50');
    }
  }

  @include config.breakpoint('xl') {
    left: config.getSpacer('md');
    transform: unset;
  }

  @include config.breakpoint('xl', 'down') {
    & button {
      border: none;
    }
    
    & button:hover {
      background-color: transparent;
      border: none;
    }
  }
}

.nav-list {
  padding-top: config.getSpacer('md');
  padding-right: config.getSpacer('xs');

  @include config.breakpoint('md', 'down') {
    padding-right: 0;
  }
}

.nav-list-item {
  display: block;

  & > a {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;

    @include config.breakpoint('md', 'down') {
      display: flex;
      justify-content: center;
    }
  }

  & > a > span {
    padding-left: 10px;
  }

  & > a > span > svg,
  & > a > span > span > svg {
    display: inline-block;
    margin-bottom: -5px;
    height: 24px;
    width: 24px;
    z-index: 1;
    position: relative;
    left: -5px;
  }

  &:hover > a,
  &:focus > a,
  & [aria-current='page'] {
    color: config.getColour('primary50');
    position: relative;
  }

  &:hover > a > span::after,
  &:focus > a > span::after,
  & > [aria-current='page'] > span::after {
    content: '';
    display: block;
    position: absolute;
    left: 17px;
    width: 40px;
    height: 30px;
    top: 12px;
    border-radius: 25px;
    z-index: 0;
  }

  & > [aria-current='page'] > span::after {
    background-color: config.getColour('grey80');
  }

  &:hover > a > span > svg path,
  &:focus > a > span > svg path,
  & > [aria-current='page'] > span > svg path {
    fill: config.getColour('primary50');
  }
}

.avatar-wrapper {
  @include config.breakpoint('sm', 'down') {
    padding: config.getSpacer('xxs') 0;
  }

  & > div {
  @include config.breakpoint('sm', 'down') {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
}
}

.logo-wrapper > a > svg {
  @include config.breakpoint('sm', 'down') {
    margin: 5px 0;
    transform: scale(0.8);
  }
}

.icon-label {
  @include config.breakpoint('xl') {
    padding-left: 5px;
  }

  @include config.breakpoint('xl', 'down') {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
