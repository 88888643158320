@use '@scss/index.scss' as config;

.container {
  padding: 0 config.getSpacer('xs');
  display: flex;
  flex-direction: column;
  width: 100%;

  &.flex-row {
    flex-direction: row;
  }

  &.centre {
    align-items: center;
  }

  &.full-height {
    min-height: calc(var(--vh, 1vh) * 100);
  }
}

.color-wrapper {
  display: block;

  &.black {
    background-color: config.getColour('grey100');
  }

  &.grey {
    background-color: config.getColour('grey80');
  }
}

.no-padd {
  padding: 0;
  margin: 0 auto;
}

.overflow-y-hidden {
  overflow-x: hidden;
}
