@use '@scss/index.scss' as config;

.modal-content-wrapper {
  padding: config.getSpacer('lg') !important;
  text-align: center;
  max-width: 90vw;
  @include config.borderHighlight();

  @media only screen and (min-width: 1000px) {
    min-width: 1000px;
  }

  @include config.breakpoint('xl') {
    border-radius: 15px !important;
  }

  & button {
    margin-bottom: config.getSpacer('xs');
  }
}

.modal-title {
  @include config.getHeader('h2');
  color: config.getColour('primary50');
  padding-bottom: config.getSpacer('sm');

  & + p {
    padding-bottom: config.getSpacer('sm');
  }
}

.logout-text {
  @include config.breakpoint('xl', 'down') {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}

.logout-icon {
  margin-left: -4px;

  &:hover {
    background-color: transparent;
  }

  @include config.breakpoint('xl') {
    display: none;
  }
}

.logout-text {
  font-size: config.getTypo('h300', 'size');
}

.button > button {
  @include config.breakpoint('xl', 'down') {
    border: none;
  }
}

:export {
  modalBackground: config.getColour('grey80');
}
