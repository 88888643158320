@use '@scss/index.scss' as config;

.visually-hidden {
  /* Contain text within 1px box */
  height: 1px;
  overflow: hidden;
  width: 1px;
  /* Keep the layout */
  position: absolute;
  /* Remove any visible trace (e.g. background color) */
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%); /* browsers in the future */
  /* Prevent the screen reader to skip spaces between words */
  white-space: nowrap;
}

.overlay {
  background-color: rgba(config.getColour('grey100'), 0.75) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled-icon {
  cursor: not-allowed;

  & > path {
    fill: config.getColour('grey80');
  }
}
